.footer {
	background: var(--hover-color);
	&__body {
		display: flex;
		justify-content: space-between;
		padding: 48px 0;
		@media (max-width:600px) {
			flex-direction: column;
		}
	}

	&__logo {
		flex: 0 0 150px;
		width: 150px;
		opacity: 1;
		transition: opacity 0.3s ease 0s;
		align-self: flex-start;
		margin-right: 12px;
		img {
			max-width: 100%;
		}
		@media (any-hover: hover) {
			&:hover {
				opacity: 0.45;
			}
		}
		@media (max-width:600px) {
			margin: 0 0 20px 0;
			flex: none;
		}
	}

	&__columns {
		display: flex;
		justify-content: space-evenly;
		flex: 1 1 auto;
		margin: 0 20px;
		@media (max-width:992px) {
			flex-direction: column;
			flex: 0 1 auto;
		}
		@media (max-width:600px) {
			margin: 0 0 20px;
		}
	}

	&__column {
		display: flex;
		flex-direction: column;
		margin: 0 20px;
		@media (max-width:992px) {
			margin: 0 0 20px 0;
			&:last-child{
				margin: 0;
			}
		}
	}

	&__heading {
		font-weight: bold;
		font-size: 20px;
		line-height: 120%;
		color: var(--bg-color);
		margin-bottom: 10px;
		@media (max-width:992px) {
			margin-bottom: 0;
		}
	}

	&__list {
		@media (max-width:992px) {
			display: flex;
			flex-wrap: wrap;
			li:not(:last-child){
				margin-right: 20px;
			}
		}
	}

	&__link {
		font-size: 15px;
		line-height: 180%;
		text-decoration-line: underline;
		color: var(--bg-color);
	}

	&__socials {
		display: flex;
		align-self: flex-start;
	}

	&__social {
		font-size: 28px;
		color: var(--bg-color);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-left: 12px;
		opacity: 1;
		transition: opacity 0.3s ease 0s;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		img{
			max-width: 100%;
		}
		@media (any-hover: hover) {
			&:hover {
				opacity: 0.45;
			}
		}
		@media (max-width:600px) {
			&:first-child{
				margin-left: 0;
			}
		}
	}

	&__bottom{
		border-top: 1px solid #fff;
		padding: 30px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--bg-color);
		span{
			display: inline-block;
			margin-right: 20px;
		}
		@media (max-width:600px) {
			flex-direction: column;
			span{
				margin: 0 0 10px 0;
			}
		}
	}

	&__dev{
		width: 125px;
		opacity: 1;
		transition: opacity 0.3s ease 0s;
		img{
			max-width: 100%;
		}
		@media (any-hover: hover) {
			&:hover {
				opacity: 0.45;
			}
		}
	}
}
