.articles {
	&__item {
		display: flex;
		background: var(--bg-color);
		padding: 10px;
		border-radius: 5px;
		border: 1px solid #dfe0e8;
		transition: all 0.3s ease 0s;
		box-shadow: 0px 0px 0px rgba(30, 35, 66, 0.15);
		&:not(:last-child){
			margin-bottom: 30px;
		}
		@media (max-width:600px) {
			flex-direction: column;
			&:not(:last-child){
				margin-bottom: 20px;
			}
		}
		@media (any-hover: hover) {
			&:hover {
				box-shadow: 5px 15px 40px rgba(30, 35, 66, 0.15);
			}
		}
	}

	&__image {
		overflow: hidden;
		position: relative;
		min-height: 215px;
		flex: 0 0 300px;
		border-radius: 5px;
		margin-right: 30px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width:767px) {
			margin-right: 20px;
			flex: 0 0 250px;
		}
		@media (max-width:600px) {
			margin-right: 0;
			flex: 0 0 200px;
		}
		@media (max-width:425px) {
			flex: 0 0 150px;
			min-height: 150px;
		}
	}

	&__info {
		padding: 25px 10px 25px 0;
		@media (max-width:600px) {
			padding: 25px 0 0 0;
		}
	}

	&__date {
		font-size: 14px;
		line-height: 120%;
		color: var(--grey);
		margin-bottom: 4px;
	}

	&__heading {
		margin-bottom: 12px;
		display: inline-block;
		transition: color 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				color: var(--hover-color);
			}
		}
	}

	&__text {
		margin-bottom: 22px;
	}

	&__btn {
		display: inline-block;
		@media (max-width:600px) {
			display: block;
		}
	}
}
