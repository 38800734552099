// Общее
@import "./includes/normalize";
@import "./includes/fonts";
@import "./includes/socials";

// Общие классы
:root {
	--font-color: #1a171b;
	--bg-color: #fff;
	--bg-secondary-color: #eff5ff;
	--hover-color: #343eb8;
	--red: #ff3939;
	--pink: #ff8686;
	--grey: #69696e;
	--green: #59af67;
	--light: rgb(200, 200, 200);
}
html{
	scroll-behavior: smooth;
	scroll-padding-top: 100px;
}
body {
	height: 100%;
	line-height: 1;
	font-size: 16px;
	color: var(--font-color);
	font-family: "Calibri", sans-serif;
	&._lock {
		overflow: hidden;
	}
}
.wrapper {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}
main {
	flex: 1 1 auto;
}
.container {
	margin: 0 auto;
	max-width: 1180px;
	padding: 0 20px;
}
.title {
	font-weight: 700;
	font-size: 42px;
	line-height: 120%;
	text-align: center;
	word-break: break-word;
	color: var(--font-color);
	@media (max-width: 1024px) {
		font-size: 36px;
	}
	@media (max-width: 767px) {
		font-size: 30px;
	}
}
.text {
	font-size: 15px;
	line-height: 140%;
	color: var(--font-color);
}
.heading {
	font-weight: 700;
	color: var(--font-color);
	line-height: 120%;
	margin-bottom: 12px;
	font-size: 24px;
	word-break: break-word;
}
.btn {
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	font-weight: 700;
	font-size: 15px;
	line-height: 120%;
	color: var(--font-color);
	transition: all 0.3s ease 0s;
	text-align: center;
	cursor: pointer;
	&.btn--red {
		color: var(--bg-color);
		padding: 11px 35px;
		background: var(--red);
		box-shadow: 0px 4px 25px rgba(255, 57, 57, 0.53);
		border: 1px solid var(--red);
		@media (any-hover: hover) {
			&:hover {
				background: transparent;
				box-shadow: 0px 0px 0px rgba(255, 57, 57, 0.53);
				color: var(--font-color);
			}
		}
	}
	&.btn--blue {
		color: var(--bg-color);
		background: var(--hover-color);
		padding: 11px 35px;
		border: 1px solid var(--hover-color);
		@media (any-hover: hover) {
			&:hover {
				background: transparent;
				color: var(--font-color);
			}
		}
	}
}
.subtitle {
	font-weight: 700;
	font-size: 36px;
	line-height: 120%;
	text-align: center;
	word-break: break-word;
	color: var(--font-color);
	margin-bottom: 30px;
	@media (max-width: 1024px) {
		font-size: 32px;
	}
	@media (max-width:992px) {
		margin-bottom: 20px;
	}
	@media (max-width: 767px) {
		font-size: 28px;
	}
}
.checkbutton {
	border: 1px solid #dfe0e8;
	border-radius: 90px;
	background: var(--bg-color);
	padding: 11px 28px;
	transition: all 0.3s ease 0s;
	margin: 6px;
	color: var(--grey);
	&.checkbutton--active{
		background: var(--hover-color);
		color: var(--bg-color);
		border: 1px solid var(--hover-color);
		@media (any-hover: hover) {
			&:hover {
				border: 1px solid var(--hover-color);
				color: var(--bg-color);
			}
		}
	}
	@media (any-hover: hover) {
		&:hover {
			border: 1px solid var(--font-color);
			color: var(--font-color);
		}
	}
}
.checkbox {
	display: flex;
	align-items: flex-end;
	padding-bottom: 9px;
	&__input {
		display: none;
		&:checked + label{
			&:after{
				transform: scale(1);
			}
		}
	}

	&__label {
		font-size: 15px;
		line-height: 120%;
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		position: relative;
		&:before {
			content: "";
			border: 1px solid #dfe0e8;
			align-self: flex-start;
			border-radius: 5px;
			height: 24px;
			width: 24px;
			flex: 0 0 24px;
			margin-right: 12px;
			background: var(--bg-color);
			transition: border-color 0.3s ease 0s;
		}
		&:after{
			content: '';
			width: 16px;
			height: 16px;
			position: absolute;
			top: 4px;
			left: 4px;
			border-radius: 5px;
			background: var(--hover-color);
			transition: all 0.3s ease 0s;
			transform: scale(0);
		}
		&._error{
			&:before{
				border: 1px solid red;
			}
		}
	}
}
._flyImage{
	opacity: 1;
	position: fixed;
	z-index: 100;
	transition: all 1s ease 0s;
	overflow: hidden;
	height: 220px;
	display: flex;
	img {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
}
.balloon-title{
	color: #000;
}

// Спиннер
.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}
.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}
.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}
.sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}
@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}
@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}

// Компоненты
@import "./includes/mainscreen";
@import "./includes/header";
@import "./includes/footer";
@import "./includes/top-section";
@import "./includes/pagination";
@import "./includes/gallery";
@import "./includes/photos";
@import "./includes/videos";
@import "./includes/templates";
@import "./includes/featured";
@import "./includes/request";
@import "./includes/filter";
@import "./includes/support";
@import "./includes/testimonials";
@import "./includes/news";
@import "./includes/articles";
@import "./includes/article";
@import "./includes/contacts";
@import "./includes/flat";
@import "./includes/popup";
