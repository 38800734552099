.pagination {
	margin: 40px 0px 60px 0px;
	@media (max-width: 767px) {
		margin: 30px 0;
	}

	&__list {
		display: flex;
		align-items: center;
		justify-content: center;
		li {
			margin: 0 4px;
		}
		@media (max-width: 767px) {
			li {
				&:nth-of-type(5),
				&:nth-of-type(7) {
					display: none;
				}
			}
		}
		@media (max-width: 500px) {
			li {
				&:first-child,
				&:last-child {
					display: none;
				}
			}
		}
	}

	&__link {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: var(--bg-secondary-color);
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 700;
		font-size: 15px;
		line-height: 120%;
		text-align: center;
		color: var(--font-color);
		transition: background-color 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				background-color: var(--pink);
			}
		}
		@media (max-width: 767px) {
			width: 30px;
			height: 30px;
			font-size: 12px;
		}
	}

	&__link--start {
		background: url("../images/arrow-left.svg") 20px center no-repeat,
			url("../images/arrow-left.svg") 10px center no-repeat, var(--bg-secondary-color);
		@media (max-width: 767px) {
			background: url("../images/arrow-left.svg") 14px center/7px auto no-repeat,
				url("../images/arrow-left.svg") 8px center/7px auto no-repeat, var(--bg-secondary-color);
		}
	}

	&__link--previous {
		background: url("../images/arrow-left.svg") center no-repeat, var(--bg-secondary-color);
		@media (max-width: 767px) {
			background: url("../images/arrow-left.svg") center/7px auto no-repeat, var(--bg-secondary-color);
		}
	}

	&__link--chosen {
		background: var(--red);
		color: var(--bg-color);
	}

	&__link--next {
		background: url("../images/arrow-right.svg") center no-repeat, var(--bg-secondary-color);
		@media (max-width: 767px) {
			background: url("../images/arrow-right.svg") center/7px auto no-repeat, var(--bg-secondary-color);
		}
	}

	&__link--end {
		background: url("../images/arrow-right.svg") 22px center no-repeat,
			url("../images/arrow-right.svg") 12px center no-repeat, var(--bg-secondary-color);
		@media (max-width: 767px) {
			background: url("../images/arrow-right.svg") 16px center/7px auto no-repeat,
				url("../images/arrow-right.svg") 10px center/7px auto no-repeat, var(--bg-secondary-color);
		}
	}
}
