.header {
	position: fixed;
	padding: 15px 0;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	transition: transform 0.3s ease 0s, background-color 0.3s ease 0s, box-shadow 0.3s ease 0s;
	&._paint{
		background: var(--bg-color);
		box-shadow: 0px 10px 20px 0px rgba(34, 60, 80, 0.1);
	}
	&._hide{
		transform: translate3d(0, -100%, 0);
	}
	@media (max-width: 992px) {
		padding: 10px 0;
		min-height: 64px;
	}

	&__body {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__logo {
		width: 150px;
		display: inline-block;
		img {
			max-width: 100%;
		}
		@media (max-width: 992px) {
			position: relative;
			z-index: 2;
			width: 100px;
		}
	}

	&__favourites{
		width: 26px;
		margin: 10px 20px;
		display: block;
		margin-left: auto;
		position: relative;
		z-index: 1;
		svg{
			max-width: 100%;
			display: block;
			transition: fill 0.3s ease 0s;
		}
		&.header__favourites--active{
			svg{
				fill: var(--hover-color);
			}
		}
		@media (any-hover: hover) {
			&:hover {
				svg{
					fill: var(--hover-color);
				}
			}
		}
	}

	&__phone {
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		line-height: 120%;
		font-size: 18px;
		font-weight: 700;
		color: var(--font-color);
		svg {
			width: 22px;
			margin-right: 10px;
			transition: fill 0.3s ease 0s;
			fill: #babcd0;
		}
		span {
			transition: color 0.3s ease 0s;
		}
		@media (any-hover: hover) {
			&:hover {
				span {
					color: var(--hover-color);
				}
				svg {
					fill: var(--hover-color);
				}
			}
		}
		@media (max-width: 992px) {
			position: relative;
			z-index: 2;
			svg {
				fill: var(--font-color);
			}
		}
		@media (max-width:500px) {
			span{
				display: none;
			}
		}
	}

	&__burger {
		@media (min-width: 993px) {
			display: none;
		}
		@media (max-width: 992px) {
			margin-left: 20px;
			z-index: 5;
			display: block;
			position: relative;
			width: 30px;
			height: 18px;
			&:after,
			&:before,
			span {
				position: absolute;
				left: 0;
				height: 2px;
				width: 100%;
				transition: all 0.3s ease 0s;
				background: var(--font-color);
			}
			&:after,
			&:before {
				content: "";
			}
			&:after {
				bottom: 0;
			}
			&:before {
				top: 0;
			}
			span {
				top: 50%;
				transform: scale(1) translate(0, -50%);
			}
			&._active{
				span{
					transform: scale(0) translate(0, -50%);
				}
				&:before{
					top: 50%;
					transform: rotate(-45deg) translate(0, -50%);
				}
				&:after{
					bottom: 50%;
					transform: rotate(45deg) translate(0, 50%);
				}
			}
		}
	}
}
.menu {
	flex: 1 1 auto;
	@media (max-width:992px) {
		position: fixed;
		top: 0;
		left: -100%;
		width: 100%;
		height: 100%;
		background: var(--bg-color);
		padding: 100px 0px 50px 0px;
		transition: left 0.3s ease 0s;
		overflow: auto;
		&:before {
			content: "";
			position: fixed;
			width: 100%;
			top: 0;
			left: 0;
			height: 64px;
			background: var(--bg-color);
			z-index: 2;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: all 0.3s ease 0s;
		}
		&._active {
			left: 0;
			&:before{
				opacity: 1;
				visibility: visible;
				pointer-events: all;
			}
		}
	}

	&__list {
		padding: 0 20px;
		& > li {
			position: relative;
			margin: 0px 10px;
			display: flex;
			align-items: center;
			@media (max-width: 992px) {
				flex-wrap: wrap;
				margin: 0;
				&:not(:last-child) {
					margin: 0px 0px 30px 0px;
				}
				&._active {
					.menu__sub-list {
						display: block;
					}
					.menu__link {
						color: var(--hover-color);
					}
					.menu__sub-list {
						opacity: 1;
						visibility: visible;
						transform: translate(0, 0);
						pointer-events: all;
					}
					.menu__arrow {
						transform: rotate(180deg) translate(0, -2px);
						svg {
							fill: var(--hover-color);
						}
					}
				}
			}
		}
		@media (min-width: 993px) {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			& > li {
				padding: 5px 0 10px 0;
				&:hover {
					.menu__sub-list {
						opacity: 1;
						visibility: visible;
						transform: translate(0, 0);
						pointer-events: all;
					}
					.menu__arrow {
						transform: rotate(180deg) translate(0, -4px);
						svg {
							fill: var(--hover-color);
						}
					}
				}
			}
		}
	}

	&__link {
		color: var(--font-color);
		line-height: 120%;
		font-size: 16px;
		font-weight: 700;
		transition: color 0.3s ease 0s;
		&.menu__link--active{
			color: var(--hover-color);
		}
		@media (any-hover: hover) {
			&:hover {
				color: var(--hover-color);
			}
		}
		@media (max-width: 992px) {
			font-size: 24px;
			flex: 1 1 auto;
		}
	}

	&__arrow {
		transition: all 0.3s ease 0s;
		margin-left: 4px;
		svg {
			width: 8px;
			fill: var(--font-color);
			transition: all 0.3s ease 0s;
		}
		@media (max-width:1024px) {
			margin-left: 8px;
			svg {
				width: 12px;
			}
		}
	}

	&__sub-list {
		position: absolute;
		z-index: 1;
		top: 100%;
		left: -15px;
		min-width: 200px;
		padding: 10px 0;
		box-shadow: 5px 15px 40px rgba(30, 35, 66, 0.15);
		border-radius: 5px;
		background: var(--bg-color);
		&::before {
			content: "";
			background: var(--bg-color);
			border-radius: 5px;
			transform: rotate(-45deg);
			position: absolute;
			top: 0;
			left: 15px;
			width: 36px;
			height: 36px;
			display: block;
			z-index: 0;
		}
		@media (min-width: 993px) {
			transform: translate(0px, 20%);
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: all 0.3s ease 0s;
		}
		@media (max-width: 992px) {
			left: 0;
			position: relative;
			background: var(--bg-secondary-color);
			flex: 1 1 100%;
			margin: 10px 0px 0px 0px;
			display: none;
			&::before {
				display: none;
			}
			li{
				&:not(:last-child){
					margin: 0px 0px 5px 0px;
				}
			}
		}
	}

	&__sub-link {
		position: relative;
		line-height: 120%;
		font-size: 15px;
		color: var(--font-color);
		border-radius: 5px;
		padding: 5px 15px;
		display: block;
		transition: background-color 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				background: var(--bg-secondary-color);
			}
		}
		@media (max-width: 992px) {
			font-size: 20px;
			line-height: 1;
		}
	}
}
