.cards {
	margin-bottom: 70px;
	@media (max-width:992px) {
		margin-bottom: 30px;
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		@media (max-width:992px) {
			margin: 0 -10px;
		}
	}

	&__item {
		flex: 0 0 33.333%;
		padding: 15px;
		@media (max-width:992px) {
			padding: 10px;
		}
		@media (max-width:767px) {
			flex: 0 0 50%;
		}
		@media (max-width:500px) {
			flex: 0 0 100%;
		}
	}

	&__wrap {
		border-radius: 5px;
		overflow: hidden;
		position: relative;
		border: 1px solid #dfe0e8;
		border-radius: 5px;
		transition: all 0.3s ease 0s;
		box-shadow: 0px 0px 0px 0px rgba(34, 60, 80, 0.1);
		transition: all 0.3s ease 0s;
		display: flex;
		flex-direction: column;
		height: 100%;
		@media (any-hover: hover) {
			&:hover {
				box-shadow: 0px 10px 20px 0px rgba(34, 60, 80, 0.1);
			}
		}
	}

	&__like {
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 1;
		background: var(--bg-color);
		box-shadow: 5px 15px 40px rgba(30, 35, 66, 0.15);
		border-radius: 5px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: opacity 0.3s ease 0s;
		svg {
			width: 18px;
			transition: all 0.3s ease 0s;
			pointer-events: none;
		}
		&.cards__like--featured {
			svg {
				fill: var(--hover-color);
			}
		}
		&._hold{
			opacity: 0.45;
			cursor: default;
		}
		@media (any-hover: hover) {
			&:hover {
				svg {
					opacity: 0.45;
				}
			}
		}
	}

	&__image {
		overflow: hidden;
		position: relative;
		padding: 0 0 62% 0;
		display: flex;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&__body {
		padding: 24px 30px;
		height: 100%;
		display: flex;
		flex-direction: column;
		@media (max-width:992px) {
			padding: 15px;
		}
	}

	&__ready {
		font-weight: bold;
		font-size: 14px;
		line-height: 120%;
		color: var(--green);
		padding-left: 22px;
		background: url("../images/tick.svg") left 1px no-repeat;
		margin-bottom: 16px;
	}

	&__price {
		font-size: 16px;
		line-height: 120%;
		color: var(--grey);
		margin-bottom: 12px;
	}

	&__flat {
		font-weight: 700;
		font-size: 16px;
		line-height: 120%;
		color: var(--hover-color);
		margin-bottom: 8px;
	}

	&__features {
		flex: 1 1 auto;
		margin-bottom: 16px;
		li {
			font-size: 15px;
			line-height: 140%;
		}
	}
}
.btn--advertisment {
	margin-bottom: 14px;
	border: 1px solid var(--red);
	@media (any-hover: hover) {
		&:hover {
			color: var(--bg-color);
			background: var(--red);
		}
	}
}
.btn--map {
	border: 1px solid var(--hover-color);
	@media (any-hover: hover) {
		&:hover {
			color: var(--bg-color);
			background: var(--hover-color);
		}
	}
}
