.popup {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	transition: all 0.4s ease 0s;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	overflow-y: auto;
	overflow-x: hidden;
	&._open {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		.popup__content {
			transform: translate(0, 0);
		}
	}
	&__body {
		min-height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 30px 20px;
	}

	&__content {
		position: relative;
		background: var(--bg-color);
		max-width: 1000px;
		width: 100%;
		padding: 40px;
		border-radius: 5px;
		box-shadow: 5px 15px 40px rgba(30, 35, 66, 0.15);
		transition: all 0.4s ease 0s;
		transform: translate(0, -50%);
		@media (max-width: 767px) {
			padding: 40px 20px;
		}
		@media (max-width: 500px) {
			padding: 40px 15px;
		}
	}

	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 26px;
		height: 26px;
		cursor: pointer;
		&:after,
		&:before {
			content: "";
			background: var(--grey);
			display: block;
			height: 2px;
			width: 100%;
			position: absolute;
			left: 0;
			transition: all 0.3s ease 0s;
		}
		&:after {
			bottom: 50%;
			transform: rotate(45deg) translate(0, 50%);
		}
		&:before {
			top: 50%;
			transform: rotate(-45deg) translate(0, -50%);
		}
		@media (any-hover: hover) {
			&:hover {
				&:after,
				&:before {
					background: var(--font-color);
				}
			}
		}
	}

	&__block {
		padding-bottom: 26px;
		border-bottom: 1px solid #dfe0e8;
		margin-bottom: 32px;
		@media (max-width: 767px) {
			padding-bottom: 10px;
			margin-bottom: 20px;
		}
	}

	&__headline {
		font-weight: 700;
		font-size: 20px;
		line-height: 120%;
		margin-bottom: 10px;
	}

	&__checkbuttons {
		margin: 0 -6px 20px -6px;
		display: flex;
		flex-wrap: wrap;
	}

	&__inputs {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -6px;
		* {
			flex: 1 1 auto;
		}
	}

	&__group {
		display: flex;
		flex-direction: column;
		margin: 6px;
		span {
			font-size: 15px;
			line-height: 120%;
			display: inline-block;
			margin-bottom: 10px;
		}
	}

	&__select {
		border: 1px solid #dfe0e8;
		border-radius: 5px;
		background: var(--bg-color);
		appearance: none;
		padding: 11px 40px 11px 14px;
		cursor: pointer;
		background: url("../images/select-arrow.svg") 95% center no-repeat;
		transition: border-color 0.3s ease 0s;
		&:hover,
		&:focus {
			border: 1px solid var(--font-color);
		}
	}

	&__range {
		border-radius: 5px;
		display: flex;
		input {
			padding: 11px 14px;
			transition: all 0.3s ease 0s;
			width: 100%;
			font-size: 16px;
			&:first-child {
				border: 1px solid #dfe0e8;
				border-radius: 5px 0 0 5px;
			}
			&:last-child {
				border: 1px solid #dfe0e8;
				border-radius: 0 5px 5px 0;
			}
			&:focus,
			&:hover {
				border: 1px solid var(--font-color);
			}
		}
	}

	&__input {
		border: 1px solid #dfe0e8;
		border-radius: 5px;
		padding: 11px 14px;
		transition: border-color 0.3s ease 0s;
		font-size: 16px;
		&:focus,
		&:hover {
			border: 1px solid var(--font-color);
		}
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 32px;
		@media (max-width: 500px) {
			padding-top: 20px;
			flex-direction: column;
		}
	}

	&__clear {
		font-size: 15px;
		line-height: 120%;
		text-decoration-line: underline;
		color: #9e9ead;
		display: inline-block;
		margin-right: 20px;
		@media (max-width: 500px) {
			margin-right: 0;
			margin-bottom: 20px;
		}
	}
	&__row {
		display: flex;
		margin: 0 -10px 36px -10px;
		@media (max-width: 500px) {
			flex-direction: column-reverse;
			margin: 0 -10px 20px -10px;
		}
	}

	&__col {
		flex: 0 0 50%;
		padding: 10px;
	}

	&__flat {
		font-weight: 700;
		font-size: 18px;
		line-height: 120%;
		color: var(--hover-color);
		margin-bottom: 8px;
	}

	&__stats {
		font-size: 15px;
		line-height: 140%;
	}

	&__ready {
		font-weight: 700;
		font-size: 14px;
		line-height: 120%;
		color: var(--green);
		padding-left: 22px;
		background: url("../images/tick.svg") left 1px no-repeat;
		margin-bottom: 12px;
	}

	&__cost {
		font-weight: 700;
		font-size: 24px;
		line-height: 120%;
		margin-bottom: 6px;
	}

	&__price {
		font-size: 16px;
		line-height: 120%;
		color: var(--grey);
	}

	&__map {
		filter: drop-shadow(5px 15px 40px rgba(30, 35, 66, 0.15));
		border-radius: 5px;
		overflow: hidden;
		position: relative;
		height: 320px;
	}

	&__form {
		background: transparent;
		box-shadow: 0px 0px 0px rgba(30, 35, 66, 0);
		border-radius: 0;
		padding: 0;
	}
}
#popup-map {
	.popup__content {
		max-width: 700px;
	}
}
#popup-request{
	.popup__content {
		max-width: 500px;
	}
	.form__label{
		flex: 0 0 100%;
	}
	.form__inputs{
		margin-bottom: 0;
	}
}
#popup-thx {
	text-align: center;
	.popup__title {
		font-size: 36px;
		margin-bottom: 10px;
	}
	.popup__text {
		font-size: 20px;
	}
	.popup__content {
		max-width: 400px;
	}
}
[class*="copyrights-pane"] {
	display: none !important;
}