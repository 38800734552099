.filter {
	margin-bottom: 60px;
	@media (max-width:992px) {
		margin-bottom: 30px;
	}
	&__triggers {
		display: flex;
		flex-wrap: wrap-reverse;
	}

	&__trigger {
		font-weight: bold;
		font-size: 15px;
		line-height: 120%;
		text-align: center;
		color: var(--font-color);
		padding: 16px 40px;
		display: inline-block;
		box-shadow: 5px 15px 40px rgba(30, 35, 66, 0.15);
		border-radius: 5px 5px 0px 0px;
		transition: all 0.3s ease 0s;
		transform: translate(0, 0);
		background: var(--bg-color);
		&.filter__trigger--active {
			background: #343eb8;
			color: var(--bg-color);
			position: relative;
			z-index: 1;
		}
		@media (any-hover: hover) {
			&:hover {
				transform: translate(0, -2px);
			}
		}
		@media (max-width: 600px) {
			padding: 11px;
			flex: 0 0 50%;
		}
	}

	&__content {
		padding: 40px;
		background: var(--bg-color);
		box-shadow: 5px 15px 40px rgba(30, 35, 66, 0.15);
		border-radius: 0px 5px 5px 5px;
		position: relative;
		@media (max-width: 600px) {
			padding: 40px 20px;
		}
	}
}
.tab {
	display: none;
	&.filter__tab--active {
		display: block;
	}
	&__triggers {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -12px 30px -12px;
	}

	&__trigger {
		flex: 1 1 auto;
		margin: 0 12px;
		font-weight: 700;
		padding: 12px;
		font-size: 15px;
		line-height: 120%;
		text-align: center;
		color: var(--grey);
		border-bottom: 2px solid #dfe0e8;
		border-radius: 10px;
		transition: all 0.3s ease 0s;
		&.tab__trigger--active {
			border-bottom: 2px solid var(--hover-color);
			color: var(--font-color);
		}
		@media (any-hover: hover) {
			&:hover {
				border-bottom: 2px solid var(--hover-color);
				color: var(--font-color);
			}
		}
	}

	&__item {
		display: none;
		&.tab__item--active {
			display: block;
		}
	}

	&__inputs {
		display: flex;
		flex-wrap: wrap;
		margin: 0px -10px 20px -10px;
		overflow: hidden;
		height: 100px;
		&._spread{
			height: auto;
		}
	}

	&__label {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		margin: 10px;
		span {
			font-size: 15px;
			line-height: 120%;
			color: var(--font-color);
			display: inline-block;
			margin-bottom: 10px;
			font-weight: 700;
		}
	}

	&__select {
		color: var(--font-color);
		font-size: 16px;
		line-height: 120%;
		border: 1px solid #dfe0e8;
		border-radius: 5px;
		padding: 11px 30px 11px 18px;
		appearance: none;
		position: relative;
		background: url("../images/select-arrow.svg") 95% center no-repeat;
		cursor: pointer;
		transition: all 0.3s ease 0s;
		min-height: 44px;
		&:hover,
		&:focus {
			border: 1px solid var(--hover-color);
		}
	}

	&__flats {
		border: 1px solid #dfe0e8;
		border-radius: 5px;
		padding: 5px;
		display: flex;
		min-height: 44px;
		transition: all 0.3s ease 0s;
		&:hover{
			border: 1px solid var(--hover-color);
		}
	}

	&__flat {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		background: var(--bg-color);
		min-width: 40px;
		flex: 1 1 auto;
		border: 1px solid transparent;
		transition: all 0.3s ease 0s;
		font-weight: bold;
		font-size: 16px;
		line-height: 120%;
		&:not(:last-child) {
			border-right: 1px solid #dfe0e8;
		}
		&.tab__flat--active {
			border-radius: 5px;
			background: var(--hover-color);
			color: var(--bg-color);
		}
		@media (any-hover: hover) {
			&:hover {
				border-radius: 5px;
				background: var(--hover-color);
				color: var(--bg-color);
			}
		}
	}

	&__range {
		display: flex;
		border-radius: 5px;
		border: 1px solid #dfe0e8;
		min-height: 44px;
		* {
			flex: 1 1 auto;
		}
		@media (max-width: 500px) {
			input {
				flex: none;
				width: 50%;
			}
		}
	}

	&__input {
		padding: 11px 18px;
		transition: all 0.3s ease 0s;
		border: 1px solid transparent;
		font-size: 16px;
		line-height: 120%;
		&:first-child {
			border-right: 1px solid #dfe0e8;
			border-radius: 5px 0 0 5px;
		}
		&:last-child {
			border-radius: 0 5px 5px 0;
		}
		&:hover,
		&:focus {
			border: 1px solid var(--hover-color);
		}
	}

	&__search {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 -10px;
		* {
			margin: 10px;
		}
		input {
			flex: 1 1 auto;
			border: 1px solid #dfe0e8;
			border-radius: 5px !important;
			font-size: 16px;
			line-height: 120%;
			min-height: 44px;
		}
	}

	&__advanced {
		font-size: 15px;
		line-height: 120%;
		text-decoration-line: underline;
		color: var(--hover-color);
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	&__btn {
		background: var(--red);
		box-shadow: 0px 0px 0px rgba(255, 57, 57, 0.53);
		font-weight: 700;
		font-size: 15px;
		line-height: 120%;
		color: #ffffff;
		padding: 11px 50px;
		transition: all 0.3s ease 0s;
		flex: 1 1 auto;
		@media (any-hover: hover) {
			&:hover {
				background: #e31111;
				box-shadow: 0px 4px 25px rgba(255, 57, 57, 0.53);
			}
		}
		@media (max-width: 500px) {
			padding: 11px;
		}
	}
}
