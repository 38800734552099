.contacts {
	margin-bottom: 60px;
	@media (max-width:992px) {
		margin-bottom: 30px;
	}
	&__bar {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 25px;
		background: var(--bg-color);
		border: 1px solid #dfe0e8;
		border-radius: 5px;
		margin-bottom: 40px;
		@media (max-width:767px) {
			padding: 10px;
		}
	}

	&__unit {
		display: flex;
		align-items: center;
		margin: 10px;
	}

	&__icon {
		flex: 0 0 60px;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background: var(--hover-color);
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 14px;
		img {
			width: 25px;
		}
	}

	&__info {
		a {
			white-space: nowrap;
			@media (any-hover: hover) {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__caption {
		font-weight: 700;
		font-size: 20px;
		line-height: 120%;
		margin-bottom: 5px;
	}

	&__btn {
		margin: 10px;
		@media (max-width:992px) {
			flex: 1 1 100%;
		}
	}

	&__team {
		margin-bottom: 70px;
		@media (max-width:992px) {
			margin-bottom: 30px;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 32px;
		line-height: 130%;
		margin-bottom: 12px;
		@media (max-width:500px) {
			text-align: center;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -24px;
	}

	&__item {
		flex: 0 0 50%;
		display: flex;
		padding: 24px;
		@media (max-width:767px) {
			flex-direction: column;
			align-items: center;
		}
		@media (max-width:500px) {
			flex: 0 0 100%;
		}
	}

	&__image {
		overflow: hidden;
		position: relative;
		height: 200px;
		width: 200px;
		flex: 0 0 200px;
		border-radius: 50%;
		margin-right: 30px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width:992px) {
			height: 120px;
			width: 120px;
			flex: 0 0 120px;
		}
		@media (max-width:767px) {
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	&__position {
		margin-bottom: 22px;
	}

	&__mail {
		font-weight: 700;
		display: block;
		color: var(--hover-color);
		text-decoration: underline;
		margin-bottom: 22px;
		padding-left: 32px;
		background: url('../images/mail-blue.svg') left center / 20px auto no-repeat;
	}

	&__phone {
		font-weight: 700;
		display: block;
		padding-left: 32px;
		background: url('../images/phone-blue.svg') left center / 20px auto no-repeat;
		&:not(:last-child){
			margin-bottom: 10px;
		}
		@media (any-hover: hover) {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__map {
		border-radius: 5px;
		overflow: hidden;
		height: 360px;
		iframe{
			width: 100%;
			height: 100%;
		}
		@media (max-width:500px) {
			height: 250px;
		}
	}
}
