.photos {
	&__list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		@media (max-width:992px) {
			margin: 0 -10px;
		}
	}

	&__item {
		flex: 0 0 33.333%;
		padding: 15px;
		@media (max-width:992px) {
			flex: 0 0 50%;
			padding: 10px;
		}
		@media (max-width:600px) {
			flex: 0 0 100%;
		}
	}

	&__link {
		display: block;
		overflow: hidden;
		position: relative;
		padding: 0 0 70% 0;
		border-radius: 5px;
		box-shadow: 0px 0px 0px 0px rgba(34, 60, 80, 0.1);
		transition: all 0.3s ease 0s;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (any-hover: hover) {
			&:hover {
				box-shadow: 0px 10px 20px 0px rgba(34, 60, 80, 0.1);
			}
		}
	}
}