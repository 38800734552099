.top-section {
	background: var(--bg-secondary-color);
	padding-top: 120px;
	padding-bottom: 110px;
	margin-bottom: -70px;
	@media (max-width: 992px) {
		padding-top: 80px;
	}
	&__title {
		margin-bottom: 12px;
	}

	&__date {
		font-size: 14px;
		line-height: 120%;
		text-align: center;
		color: var(--grey);
	}
}
.breadcrumbs {
	margin-bottom: 10px;
	&__list {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		li {
			padding: 2px;
			text-align: center;
			&:not(:last-child) {
				a {
					text-decoration-line: underline;
					@media (any-hover: hover) {
						&:hover {
							text-decoration: none;
						}
					}
				}
				&:after {
					content: "/";
					display: inline-block;
					padding-left: 5px;
				}
			}
		}
	}

	&__link {
		font-size: 14px;
		line-height: 120%;
		text-align: center;
		color: var(--font-color);
	}
}
