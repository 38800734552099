.mainscreen {
	margin-bottom: -115px;
	background: var(--bg-secondary-color);
	@media (max-width:992px) {
		padding-bottom: 100px;
		margin-bottom: -100px;
	}
	&__body {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width:500px) {
			flex-direction: column;
		}
	}

	&__info{
		padding: 200px 0px 230px 0px;
		flex: 0 0 50%;
		@media (max-width:992px) {
			padding: 100px 0;
		}
		@media (max-width:500px) {
			padding: 100px 0 0 0;
			flex: none;
		}
	}

	&__title {
		text-align: left;
		margin-bottom: 12px;
	}

	&__text {
		font-size: 20px;
		line-height: 120%;
		color: var(--font-color);
	}

	&__image{
		flex: 0 0 50%;
		padding-left: 30px;
		overflow: hidden;
		position: relative;
		padding: 0 0 50% 0;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 80%;
			width: 80%;
		}
		@media (max-width:992px) {
			img{
				height: 100%;
				width: 100%;
			}
		}
		@media (max-width:500px) {
			flex: none;
			min-height: 200px;
			max-width: 280px;
			width: 100%;
			margin: 30px 0;
		}
	}
}
