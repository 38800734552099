.request {
	margin-bottom: 40px;
}
.form {
	background: var(--bg-color);
	box-shadow: 5px 15px 40px rgba(30, 35, 66, 0.15);
	border-radius: 5px;
	padding: 40px;
	@media (max-width: 767px) {
		padding: 30px 20px;
	}
	&__inputs {
		margin: 0 -10px 20px -10px;
		display: flex;
		flex-wrap: wrap;
		& > * {
			flex: 0 0 33.333%;
			&:nth-of-type(4){
				flex: 0 0 100%;
			}
		}
		@media (max-width: 767px) {
			flex-direction: column;
			margin: 0 -10px 0 -10px;
		}
	}

	&__label {
		display: flex;
		flex-direction: column;
		padding: 10px;
		span {
			flex: 1 1 auto;
			font-size: 15px;
			line-height: 120%;
			color: var(--font-color);
			font-weight: 700;
			display: block;
			margin-bottom: 10px;
		}
	}

	&__input {
		border: 1px solid #dfe0e8;
		box-sizing: border-box;
		border-radius: 5px;
		padding: 11px 18px;
		font-size: 16px;
		transition: all 0.3s ease 0s;
		&::placeholder {
			font-size: 16px;
			line-height: 120%;
			color: var(--grey);
			transition: color 0.3s ease 0s;
		}
		&:hover,
		&:focus{
			border: 1px solid var(--font-color);
		}
		&._error{
			border: 1px solid red;
			&::placeholder{
				color: red;
			}
		}
	}
	&__textarea-wrap {
		margin: 0 -10px 10px -10px;
	}
	textarea.form__input {
		resize: vertical;
		min-height: 100px;
	}
	&__alert{
		text-align: center;
		margin-bottom: 10px;
		color: var(--red);
		transition: all 0.3s ease 0s;
		opacity: 0;
		visibility: hidden;
		&._error{
			opacity: 1;
			visibility: visible;
		}
	}
	.checkbox{
		margin-bottom: 10px;
		a{
			color: var(--hover-color);
			text-decoration: underline;
			@media (any-hover: hover) {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	&__btn {
		width: 100%;
		background: transparent;
		border: 1px solid var(--hover-color);
		@media (any-hover: hover) {
			&:hover {
				color: var(--bg-color);
				background: var(--hover-color);
			}
		}
	}
}
