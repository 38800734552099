.testimonials {
	background: var(--bg-secondary-color);
	padding-top: 220px;
	padding-bottom: 70px;
	margin-top: -150px;
	@media (max-width:992px) {
		padding-bottom: 30px;
	}

	&__body {
		position: relative;
	}

	&__heading {
		text-align: center;
		margin-bottom: 6px;
	}

	&__text {
		font-size: 17px;
		line-height: 120%;
		text-align: center;
		color: var(--font-color);
		margin-bottom: 20px;
	}

	&__button{
		text-align: center;
	}

	&__btn {
		display: inline-block;
	}
}
.slider {
	margin-bottom: 36px;
	&__slide {
		background: #ffffff;
		border: 1px solid #dfe0e8;
		border-radius: 5px;
		padding: 30px 20px 20px 20px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	&__top {
		display: flex;
		align-items: center;
		padding-bottom: 22px;
		margin-bottom: 22px;
		border-bottom: 1px solid #dfe0e8;
		min-height: 81px;
	}

	&__image {
		width: 58px;
		height: 58px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 20px;
		img {
			max-width: 100%;
		}
	}

	&__description {
		display: flex;
		flex-direction: column;
	}

	&__position {
		font-size: 14px;
		line-height: 120%;
		color: var(--grey);
	}

	&__name {
		font-weight: 700;
		font-size: 16px;
		line-height: 120%;
		color: var(--font-color);
		margin-bottom: 4px;
	}

	&__amount {
		font-size: 14px;
		line-height: 120%;
		text-decoration-line: underline;
		color: #343eb8;
		@media (any-hover: hover) {
			&:hover {
				text-decoration: none;
			}
		}
	}

	&__info {
		min-height: 143px;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		font-size: 15px;
		line-height: 150%;
		color: var(--font-color);
		margin-bottom: 16px;
		background: url("../images/quotes.svg") right top no-repeat, #ffffff;
		span {
			display: block;
			margin-bottom: 8px;
			word-break: break-word;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
			max-height: 115px;
			flex: 1 1 auto;
		}
		a {
			color: var(--hover-color);
			text-decoration: underline;
			@media (any-hover: hover) {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	&__bottom {
		min-height: 70px;
		background: #fafcff;
		border-top: 1px solid #dfe0e8;
		padding: 12px 20px;
		margin: 0px -20px -20px -20px;
	}

	&__caption {
		font-weight: 700;
		font-size: 15px;
		line-height: 150%;
	}

	&__date {
		font-size: 15px;
		line-height: 150%;
	}

	&__button-prev {
		width: 34px;
		height: 34px;
		cursor: pointer;
		position: absolute;
		top: 40%;
		left: -60px;
		z-index: 1;
		svg {
			transition: fill 0.3s ease 0s;
		}
		&:hover {
			svg {
				fill: var(--font-color);
			}
		}
		@media (max-width:1365px) {
			top: 0px;
			left: 0;
		}
	}

	&__button-next {
		width: 34px;
		height: 34px;
		cursor: pointer;
		position: absolute;
		top: 40%;
		right: -60px;
		z-index: 1;
		svg {
			transition: fill 0.3s ease 0s;
		}
		&:hover {
			svg {
				fill: var(--font-color);
			}
		}
		@media (max-width:1365px) {
			top: 0px;
			right: 0;
		}
	}
}
.swiper-slide{
	height: auto !important;
}
