.article {
	&.article--no-image{
		padding-top: 120px;
	}
	&__body {
		p {
			font-size: 17px !important;
			line-height: 170% !important;
			margin-bottom: 30px !important;
		}
		@media (max-width:992px) {
			P{
				margin-bottom: 20px !important;
			}
		}
		img{
			max-width: 100% !important;
		}
	}

	&__image {
		overflow: hidden;
		position: relative;
		padding: 0 0 40% 0;
		border-radius: 5px;
		margin-bottom: 45px;
		min-height: 200px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width: 992px) {
			margin-bottom: 30px;
		}
	}
}
