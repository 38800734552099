@font-face {
  font-family: 'socials';
  src:
    url('../fonts/socials.ttf?egucfl') format('truetype'),
    url('../fonts/socials.woff?egucfl') format('woff'),
    url('../fonts/socials.svg?egucfl#socials') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="social-"]::before, [class*=" social-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'socials' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.social-fb:before {
  content: "\e900";
}
.social-insta:before {
  content: "\e901";
}
.social-ok:before {
  content: "\e902";
}
.social-skype:before {
  content: "\e903";
}
.social-tg:before {
  content: "\e904";
}
.social-viber:before {
  content: "\e905";
}
.social-vk:before {
  content: "\e906";
}
.social-wa:before {
  content: "\e907";
}
.social-yt:before {
  content: "\e908";
}
