.temp {
	&__bg {
		overflow: hidden;
		position: relative;
		padding: 0 0 20% 0;
		border-radius: 5px;
		margin-bottom: 60px;
		min-height: 150px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}
.theme {
	&__block{
		margin-bottom: 30px;
	}

	&__details {
		margin-bottom: 10px;
		&[open] {
			background: rgb(244, 247, 255);
			.theme__summary {
				border-radius: 5px 5px 0px 0px;
				border: 1px solid var(--hover-color);
				&::before {
					transform: rotate(90deg);
				}
			}
			.theme__info{
				animation: slide .3s;
			}
		}
	}

	&__summary {
		transition: all 0.3s ease 0s;
		border-radius: 5px;
		padding: 10px;
		border: 1px solid var(--light);
		font-size: 20px;
		cursor: pointer;
		list-style: none;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		&::before {
			content: "";
			display: inline-block;
			background: url("../images/arrow-details.svg") center no-repeat;
			width: 30px;
			height: 30px;
			transition: all 0.3s ease 0s;
			transform: rotate(0);
		}
		span{
			margin-right: auto;
		}
		@media (any-hover: hover) {
			&:hover {
				border: 1px solid var(--hover-color);
			}
		}
	}

	&__icon {
		height: 30px;
		display: flex;
		align-items: center;
		margin: 5px 5px 5px 20px;
		padding-left: 40px;
		color: inherit;
		&[href$=".pdf"]{
			background: url('../images/pdf.svg') left center/contain no-repeat;
		}
		&[href$=".rar"]{
			background: url('../images/rar.svg') left center/contain no-repeat;
		}
		&[href$=".zip"]{
			background: url('../images/zip.svg') left center/contain no-repeat;
		}
		&[href$=".jpg"]{
			background: url('../images/jpg.svg') left center/contain no-repeat;
		}
		&[href$=".xlsx"]{
			background: url('../images/xls.svg') left center/contain no-repeat;
		}
		&[href$=".docx"]{
			background: url('../images/docx.svg') left center/contain no-repeat;
		}
		img{
			max-width: 100%;
		}
		@media (any-hover: hover) {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__info {
		border: 1px solid var(--hover-color);
		border-top: 0;
		border-radius: 0px 0px 5px 5px;
		padding: 20px;
		line-height: 150%;
		font-size: 18px;
	}
}

@keyframes slide {
	0% {
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		transform: translate(0, -30px);
	}
	100% {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		transform: translate(0, 0);
	}
}
