.news {
	padding: 60px 0px 70px 0px;
	@media (max-width:992px) {
		padding: 30px 0px 40px 0px;
	}

	&__subtitle {
		margin-bottom: 45px;
		@media (max-width:992px) {
			margin-bottom: 20px;
		}
	}

	&__info {
		display: flex;
		@media (max-width:992px) {
			flex-direction: column-reverse;
		}
	}

	&__feed {
		margin-right: 30px;
		flex: 0 0 32%;
		display: flex;
		@media (max-width:992px) {
			margin-right: 0;
			flex: none;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@media (max-width:992px) {
			width: 100%;
		}
	}

	&__item {
		&:not(:last-child){
			margin-bottom: 20px;
		}
	}

	&__link {
		display: block;
		padding: 22px;
		border: 1px solid #dfe0e8;
		border-radius: 5px;
		transition: all 0.3s ease 0s;
		background: var(--bg-color);
		@media (any-hover: hover) {
			&:hover {
				box-shadow: 5px 15px 40px rgba(30, 35, 66, 0.15);
				border-radius: 5px;
				.news__caption {
					color: var(--hover-color);
				}
			}
		}
	}

	&__date {
		font-size: 14px;
		line-height: 120%;
		color: var(--grey);
		margin-bottom: 8px;
	}

	&__caption {
		font-weight: 700;
		font-size: 16px;
		line-height: 120%;
		color: var(--font-color);
		transition: color 0.3s ease 0s;
	}

	&__main {
		flex: 1 1 auto;
		@media (max-width:992px) {
			margin-bottom: 20px;
		}
		@media (max-width:600px) {
			margin-bottom: 0;
		}
	}

	&__roll {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -16px;
	}

	&__piece {
		flex: 0 0 33.333%;
		padding: 0 16px;
		&:first-child {
			flex: 0 0 100%;
			margin-bottom: 36px;
			.news__image{
				padding: 0 0 41% 0;
			}
			.news__title{
				font-size: 24px;
			}
		}
		@media (any-hover: hover) {
			&:hover {
				.news__title{
					color: var(--hover-color);
				}
			}
		}
		@media (max-width:600px) {
			flex: 0 0 50%;
			margin-bottom: 20px;
			&:first-child{
				flex: 0 0 50%;
				margin-bottom: 20px;
				.news__image{
					padding: 0 0 62% 0;
				}
				.news__title{
					font-size: 18px;
				}
			}
		}
		@media (max-width:500px) {
			flex: 0 0 100%;
			&:first-child{
				flex: 0 0 100%;
			}
		}
	}

	&__anchor {
		display: block;
	}

	&__image {
		overflow: hidden;
		position: relative;
		padding: 0 0 62% 0;
		border-radius: 5px;
		margin-bottom: 20px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
		@media (max-width:992px) {
			margin-bottom: 10px;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 18px;
		line-height: 120%;
		color: var(--font-color);
		transition: color 0.3s ease 0s;
	}
}
