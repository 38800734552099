@font-face {
	font-family: "Calibri";
	src: local("Calibri Regular"), local("Calibri-Regular"), url("../fonts/Calibri.woff2") format("woff2"),
		url("../fonts/Calibri.woff") format("woff"), url("../fonts/Calibri.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Calibri";
	src: local("Calibri Bold"), local("Calibri-Bold"), url("../fonts/Calibri-Bold.woff2") format("woff2"),
		url("../fonts/Calibri-Bold.woff") format("woff"), url("../fonts/Calibri-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}
