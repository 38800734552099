.support {
	&__form {
		display: flex;
		justify-content: space-between;
		padding: 40px;
		background: var(--bg-color);
		box-shadow: 5px 15px 40px rgba(30, 35, 66, 0.15);
		border-radius: 5px;
		position: relative;
		z-index: 1;
		@media (max-width:767px) {
			padding: 30px 20px;
			flex-direction: column;
		}
	}

	&__info {
		margin-right: 40px;
		@media (max-width:992px) {
			margin-right: 20px;
		}
		@media (max-width:767px) {
			margin-right: 0;
			margin-bottom: 30px;
		}
	}

	&__subtitle {
		text-align: left;
		margin-bottom: 10px;
	}

	&__text {
		font-size: 17px;
		line-height: 120%;
		margin-bottom: 30px;
		a{
			font-weight: 700;
			color: inherit;
			white-space: nowrap;
			@media (any-hover: hover) {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&__inputs {
		display: flex;
		margin-bottom: 20px;
		@media (max-width:992px) {
			flex-direction: column;
		}
	}

	&__input {
		flex: 0 0 50%;
		border: 1px solid #dfe0e8;
		border-radius: 5px;
		padding: 11px 18px;
		&:first-child{
			margin-right: 30px;
		}
		transition: all 0.3s ease 0s;
		&:hover,
		&:focus{
			border: 1px solid var(--hover-color);
		}
		@media (max-width:992px) {
			flex: auto;
			&:first-child{
				margin-right: 0px;
				margin-bottom: 10px;
			}
		}
	}

	&__bottom {
		display: flex;
		align-items: center;
		@media (max-width:992px) {
			flex-direction: column;
			align-items: stretch;
		}
	}

	&__btn {
		margin-right: 30px;
		@media (max-width:992px) {
			margin-right: 0px;
			margin-bottom: 20px;
		}
	}

	&__confidentiality {
		max-width: 260px;
		a{
			color: var(--hover-color);
			@media (any-hover: hover) {
				&:hover {
					text-decoration: underline;
				}
			}
		}
		@media (max-width:992px) {
			max-width: none;
		}
	}

	&__image {
		align-self: flex-end;
		width: 356px;
		flex: 0 0 356px;
		margin-bottom: -40px;
		img {
			max-width: 100%;
		}
		@media (max-width:767px) {
			margin-bottom: -30px;
			flex: none;
			width: 100%;
			text-align: center;
		}
	}
}
