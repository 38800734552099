.flat {
	&__info {
		display: flex;
		margin-bottom: 42px;
		@media (max-width:767px) {
			flex-direction: column;
		}
	}

	&__card {
		position: relative;
		flex: 0 0 360px;
		border: 1px solid #dfe0e8;
		background: var(--bg-color);
		border-radius: 5px;
		padding: 26px 22px;
		display: flex;
		flex-direction: column;
		@media (max-width:992px) {
			flex: 0 0 280px;
			padding: 20px;
		}
		@media (max-width:767px) {
			flex: none;
		}
		@media (max-width:500px) {
			padding: 20px 10px;
		}
	}

	&__like{
		cursor: pointer;
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 1;
		background: var(--bg-color);
		box-shadow: 5px 15px 40px rgba(30, 35, 66, 0.15);
		border-radius: 5px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: opacity 0.3s ease 0s;
		svg {
			width: 18px;
			transition: all 0.3s ease 0s;
			pointer-events: none;
		}
		&.flat__like--featured {
			svg {
				fill: var(--hover-color);
			}
		}
		&._hold{
			opacity: 0.45;
			cursor: default;
		}
		@media (any-hover: hover) {
			&:hover {
				svg {
					opacity: 0.45;
				}
			}
		}
	}

	&__apartment {
		font-weight: 700;
		font-size: 18px;
		line-height: 120%;
		color: var(--hover-color);
		margin-bottom: 16px;
		padding-right: 40px;
	}

	&__table {
		margin-bottom: 10px;
	}

	&__row {
		display: flex;
	}

	&__key {
		flex: 0 0 50%;
		font-size: 15px;
		line-height: 155%;
		color: #99999e;
		margin-right: 20px;
	}

	&__value {
		flex: 0 0 50%;
		font-size: 15px;
		line-height: 155%;
		color: var(--font-color);
	}

	&__more {
		font-size: 16px;
		line-height: 155%;
		text-decoration-line: underline;
		color: var(--hover-color);
		display: inline-block;
		margin-bottom: 50px;
		@media (max-width:767px) {
			margin-bottom: 20px;
		}
	}

	&__ready {
		font-weight: 700;
		font-size: 14px;
		line-height: 120%;
		color: var(--green);
		padding-left: 22px;
		background: url("../images/tick.svg") left 1px no-repeat;
		margin-bottom: 12px;
	}

	&__cost {
		font-weight: 700;
		font-size: 24px;
		line-height: 120%;
		margin-bottom: 4px;
	}

	&__price {
		font-size: 16px;
		line-height: 120%;
		color: var(--grey);
		margin-bottom: 28px;
		flex: 1 1 auto;
	}

	&__description {
		margin-bottom: 55px;
		.flat__table{
			max-width: 400px;
		}
	}

	&__subtitle {
		text-align: left;
		margin-bottom: 10px;
	}

	&__text {
		font-size: 16px;
		line-height: 150%;
		margin-bottom: 26px;
		p {
			margin-bottom: 12px;
		}
		ul {
			padding-left: 30px;
			margin-bottom: 12px;
			li {
				list-style-type: disc;
				&:not(:last-child){
					margin-bottom: 12px;
				}
			}
		}
	}

	&__caption {
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		margin-bottom: 6px;
	}
}
.presentation {
	flex: 1 1 auto;
	margin-right: 30px;
	min-width: 0;
	background: #ffffff;
	border: 1px solid #dfe0e8;
	border-radius: 5px;
	padding: 30px;
	@media (max-width:992px) {
		margin-right: 10px;
		padding: 20px;
	}
	@media (max-width:767px) {
		margin-right: 0;
		margin-bottom: 10px;
	}
	@media (max-width:500px) {
		padding: 20px 10px;
	}
	&__triggers {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px 30px -10px;
		@media (max-width:500px) {
			margin: 0 -10px 10px -10px;
		}
	}

	&__trigger {
		font-size: 16px;
		line-height: 120%;
		text-align: center;
		color: inherit;
		font-weight: 700;
		margin: 0 10px;
		padding: 6px 0;
		transition: all 0.3s ease 0s;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			height: 2px;
			width: 0;
			background: var(--hover-color);
			transition: all 0.3s ease 0s;
		}
		@media (any-hover: hover) {
			&:hover {
				color: var(--hover-color);
			}
		}
		&.presentation__trigger--active {
			color: var(--hover-color);
			&:before {
				width: 100%;
			}
		}
	}

	&__tab {
		display: none;
		&.presentation__tab--active {
			display: block;
		}
	}

	&__wrap {
		min-width: 0;
		display: flex;
	}

	&__video {
		overflow: hidden;
		position: relative;
		height: 330px;
		border-radius: 5px;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&__map {
		overflow: hidden;
		position: relative;
		height: 330px;
		border-radius: 5px;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}
.img-slider {
	flex: 1 1 auto;
	margin-right: 18px;
	@media (max-width:500px) {
		margin-right: 0;
	}
	&__image {
		overflow: hidden;
		position: relative;
		height: 330px;
		border-radius: 5px;
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	&__button-prev {
		&:after {
			display: none;
		}
	}

	&__button-next {
		&:after {
			display: none;
		}
	}
}
.img-slider-small {
	flex: 0 0 110px;
	height: 330px;
	@media (max-width:500px) {
		display: none;
	}
	
	&__slide {
		transition: opacity 0.3s ease 0s;
		&.swiper-slide-visible {
			opacity: 0.6;
		}
		&.swiper-slide-thumb-active {
			opacity: 1;
			.img-slider-small__image {
				img {
					transform: scale(0.9);
				}
			}
		}
		@media (any-hover: hover) {
			&:hover {
				opacity: 1;
			}
		}
	}

	&__image {
		overflow: hidden;
		position: relative;
		padding: 0 0 67% 0;
		border-radius: 5px;
		cursor: pointer;
		img {
			transition: all 0.3s ease 0s;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}
}
